@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@600&family=Barlow+Semi+Condensed:wght@500&family=Barlow:wght@400;500&display=swap');

.font-barlow {
  font-family: 'Barlow', sans-serif;
}

.font-barlow-condensed {
  font-family: 'Barlow Condensed', sans-serif;
}

.font-barlow-semi-condensed {
  font-family: 'Barlow Semi Condensed', sans-serif;
}