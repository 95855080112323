.border-b-3 {
  border-bottom-width: 3px;
}

.py-4\.5 {
  padding-top: 1.15rem;
  padding-bottom: 1.15rem;
}

.hide-scroll-bars::-webkit-scrollbar {
  display: none;
}

.hide-scroll-bars {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
